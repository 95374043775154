.lost-page {
  color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-top: -60px;
  } 

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  h2 {
    font-family: "Gotham Black";
    font-weight: bold;
    font-size: 4rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 16px rgb(6 6 6 / 20%);
    color: #fff;
    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 2rem;
    }
  }

  p {
    font-family: "Ocra";
    text-transform: uppercase;
    font-size: 1rem;
    text-shadow: 0px 0px 16px rgb(6 6 6 / 20%);
    margin: 0;
    color: #40158f;
  }

  img {
    width: 100px;


    @media only screen and (max-width: 600px) {
      width: 40px;
    } 
  }
}