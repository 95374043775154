@import-normalize;

@font-face {
  font-family: 'Gotham Black';
  src: local('Gotham Black'), url('../src/assets/Gotham-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Black';
  src: local('Gotham Black'), url('../src/assets/Gotham-Black.ttf') format('truetype');
} 

@font-face {
  font-family: 'Ocra';
  src: local('Ocra'), url('../src/assets/Ocra.otf') format('opentype');
}

@font-face {
  font-family: 'Ocra';
  src: local('Ocra'), url('../src/assets/Ocra.ttf') format('truetype');
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#B32CA9,#6A34F3);
  overflow: hidden;
  font-family: 'Rubik Mono One', sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

#root {
  display: flex;
  width: 100%;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
