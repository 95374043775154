.nav {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1;

  &__logo {
    width: 32px;
    margin-right: 20px;

    img {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
    }
  }
}