.socials {
  position: fixed;
  bottom: 30px;
  right: 30px;

  &__logo {
    width: 40px;
    margin-top: 20px;

    figure {
      margin: 0;
    }

    svg {
      fill: #40158f96;
      transform: scale(1);
      transition-property: transform fill;
      transition-duration: 0.2s;
      transition-timing-function: ease;

      &:hover {
        fill: #40158f;
        transform: scale(1.2);
      }
    }
  }

  &__twitch svg,
  &__twitch svg:hover {
    fill: #40158f;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 20px;

    &__logo {
      margin: 0 20px;
    }
  }
}