.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .scroll-container {
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h2 {
    font-family: "Gotham Black";
    font-weight: bold;
    font-size: 6rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 16px rgb(6 6 6 / 20%);
    color: #fff;
    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 4rem;
    }
  }

  p {
    font-family: "Ocra";
    text-transform: uppercase;
    font-size: 1rem;
    text-shadow: 0px 0px 16px rgb(6 6 6 / 20%);
    margin: 0;
    color: #40158f;
  }

  section {
    display: flex;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .section-image {
    width: 140px;
    margin-bottom: 10px;


    @media only screen and (max-width: 600px) {
      width: 100px;
    } 
  }
}